<template>
  <div class="over-speed" ref="overSpeed">
    <el-tabs type="border-card" v-model="form.type" @tab-click="clickTab">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form @getFormVal="onSubmit" :mistakeList="mistakeList" :alarmTypeList="alarmTypeList" :showCar="false" :mistakeShow="true" :alarmTypeShow="true" :platformAlarmShow="true">
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
              >导出</el-button
            >
          </analysis-form>
          <el-radio-group v-model="state">
            <el-radio :label="0"> 图表 </el-radio>
            <el-radio :label="1"> 报表 </el-radio>
          </el-radio-group>
        </div>
        <el-row v-if="state == 0">
          <el-col :span="12">
            <div class="item item2" style="height: 380px">
              <charts ref="comCharts" :option="item2Option" :id="item2"></charts>
            </div>
          </el-col>
        </el-row>
        <!-- 企业统计 -->
        <company-table
          ref="pagination1"
          :height="tableHeight"
          :total="total"
          :tableData="comArr"
          v-else
        />
      </el-tab-pane>
      <el-tab-pane label="车辆" name="2">
        <div class="form-area">
          <analysis-form @getFormVal="onSubmit" :mistakeList="mistakeList" :alarmTypeList="alarmTypeList" :mistakeShow="true" :platformAlarmShow="true" :alarmTypeShow="true">
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :total="total"
          :modelObj="modelObj"
          :typeObj="typeObj"
          :tableData="carArr"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
      <el-tab-pane label="详情" name="3">
        <!-- 报警查询明细 -->
        <alarmDetail :alarmTypeValue="alarmType"></alarmDetail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import companyTable from "./components/companyTable.vue";
import carTable from "./components/carTable.vue";
import charts from "@/components/charts/charts.vue";
import alarmDetail from "./components/alarmDetail"

import { querySpeedStatistics, querySpeedCompanyStatistics } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";

import { formatDict, formatDate, getCurent } from "@/common/utils/index";

export default {
  name: "overSpeed",
  components: {
    charts,
    analysisForm,
    companyTable,
    carTable,
    alarmDetail
  },
  data() {
    return {
      alarmTypeList:[
      {
          dictValue: '路网图超速报警',
          dictCode: '2051',
        },
        {
          dictValue: '超速报警',
          dictCode: '2012',
        },
      ],
      item2: "itemChart2",
      item2Option: {
        title: {
          text: "超速报警统计",
          left: 0,
          textStyle: {
            color: "#666666",
            fontSize: 16,
          },
          top: 0,
        },
        grid: {
          top: 45,
          left: 40,
          right: 10,
          bottom: 20,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          textStyle: {
            fontSize: 12,
          },
        },
        color: ["#FFD616", "#AD8FDF", "#02CDCE", "#EA546D", "#336FFE"],
        legend: {
          right: 0,
          textStyle: {
            color: "#333",
          },
          orient: "horizontal",
          itemWidth: 4,
          itemHeight: 4,
          itemGap: 10,
        },
        dataset: {
          dimensions: ["product", "5分钟以内", "5-10分钟以内", "超过10分钟"],
          source: [
            {
              product: "超速20%以下",
              "5分钟以内": 0,
              "5-10分钟以内": 0,
              超过10分钟: 0,
            },
            {
              product: "超速20%-50%",
              "5分钟以内": 0,
              "5-10分钟以内": 0,
              超过10分钟: 0,
            },
            {
              product: "超速50%以上",
              "5分钟以内": 0,
              "5-10分钟以内": 0,
              超过10分钟: 0,
            },
          ],
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#E9E9E9",
            },
          },
        },
        series: [
          { type: "bar", barWidth: "8%" },
          { type: "bar", barWidth: "8%" },
          { type: "bar", barWidth: "8%" },
        ],
      },
      tableHeight: 0,
      tableData: [],
      state: 0,
      form: {
        type: "1",
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
      },
      exportForm: {}, //导出
      downLoadStatus: false, //导出

      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      typeObj: null,
      modelObj: null,
      mistakeList:[],

      alarmType:[2001,2012],//超速报警
    };
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.overSpeed.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 90;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 24;
    },
    //导出
    exportData(key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId")||localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:
          key === 1 ? `超速报警统计-${getCurent()}` : `超速报警详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/illegalExport/querySpeedOverCompanyStatistics`
            : `/illegalExport/querySpeedOverVehicleStatistics`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit(value) {
      this.form = { ...this.form, ...value };
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 获取列表
    getDataList() {
      let data = { ...this.form };
      delete data.type;
      switch (this.form.type) {
        case "3":
          this.isShow = true;
          break;
        case "2":
          /**获取车辆详情*/
          querySpeedStatistics(data).then((res) => {
            if (res.code === 1000) {
              this.carArr = res.data.list;
              this.total = res.data.total;
              this.$nextTick(()=>{
                this.$refs.pagination2.layoutFun();
              })
            } else {
              this.carArr = [];
              this.total = 0;
            }
          });
          break;
        case "1":
          /**获取企业统计*/
          delete data.vehicleNos;
          querySpeedCompanyStatistics(data).then((res) => {
            if (res.code === 1000) {
              let dataset = res.data;
              this.comArr = [dataset];
              this.total = 1;
              let source = [
                {
                  product: "超速20%以下",
                  "5分钟以内": dataset.lessTwentyFive,
                  "5-10分钟以内": dataset.lessTwentyTen,
                  超过10分钟: dataset.lessTwenty,
                },
                {
                  product: "超速20%-50%",
                  "5分钟以内": dataset.middleFiftyFive,
                  "5-10分钟以内": dataset.middleFiftyTen,
                  超过10分钟: dataset.middleFifty,
                },
                {
                  product: "超速50%以上",
                  "5分钟以内": dataset.moreFiftyFive,
                  "5-10分钟以内": dataset.moreFiftyTen,
                  超过10分钟: dataset.moreFifty,
                },
              ];
              this.$set(this.item2Option.dataset, "source", source);
            } else {
              this.comArr = [];
              this.total = 0;
              let source = [
                {
                  product: "超速20%以下",
                  "5分钟以内": 0,
                  "5-10分钟以内": 0,
                  超过10分钟: 0,
                },
                {
                  product: "超速20%-50%",
                  "5分钟以内": 0,
                  "5-10分钟以内": 0,
                  超过10分钟: 0,
                },
                {
                  product: "超速50%以上",
                  "5分钟以内": 0,
                  "5-10分钟以内": 0,
                  超过10分钟: 0,
                },
              ];
              this.$set(this.item2Option.dataset, "source", source);
            }
          });
          break;
      }
      this.exportForm = { ...data };
    },
    /**切换统计|详情 */
    clickTab() {
      this.total = 0;
      this.carArr = [];
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.comArr = [];
      let source = [
        {
          product: "超速20%以下",
          "5分钟以内": 0,
          "5-10分钟以内": 0,
          超过10分钟: 0,
        },
        {
          product: "超速20%-50%",
          "5分钟以内": 0,
          "5-10分钟以内": 0,
          超过10分钟: 0,
        },
        {
          product: "超速50%以上",
          "5分钟以内": 0,
          "5-10分钟以内": 0,
          超过10分钟: 0,
        },
      ];

      this.$set(this.item2Option.dataset, "source", source);
    },
    /**分页参数切换 */
    getPageInfo(params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.carArr.length === 0) return;
      this.getDataList();
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,PCWB" }).then((res) => {
        if (res.code === 1000) {
          this.mistakeList = res.data.PCWB
          this.modelObj = formatDict(res.data.VETY);
          this.typeObj = formatDict(res.data.CPYS);
        }
      });
    },
  },
  created() {
    this.getDicts();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.over-speed {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-tabs {
      height: 100%;
      box-sizing: border-box;
      .el-row {
        margin-top: 60px;
        padding-left: 60px;
      }
    }
  }
}
.theme-project-gps {
  .over-speed {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
