<template>
  <div class="multilevel">
    <el-table
      ref="carTable"
      :data="tableData"
      stripe
      :height="tableHeight - 26"
      style="width: 100%"
    >
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="100 * $store.state.vehicle.screenCoefficient"
      ></el-table-column>
      <el-table-column label="车辆颜色">
        <template slot-scope="scope">
          {{ typeObj[Number(scope.row.licensePlateColor)] }}
        </template>
      </el-table-column>
      <el-table-column
        label="车辆类型"
        :show-overflow-tooltip="true"
        :width="130 * $store.state.vehicle.screenCoefficient"
      >
        <template slot-scope="scope">
          {{ modelObj[Number(scope.row.type)] }}
        </template>
      </el-table-column>
      <el-table-column label="20%以下">
        <el-table-column
          prop="lessTwentyFive"
          label="5分钟以下"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="lessTwentyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="lessTwenty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="20%(含)-50%以下">
        <el-table-column
          prop="middleFiftyFive"
          label="5分钟以下"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="middleFiftyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="middleFifty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="50%以上">
        <el-table-column
          prop="moreFiftyFive"
          label="5分钟以下"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="moreFiftyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="moreFifty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column prop="total" label="合计"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
    },
    total: {
      type: Number,
    },
    modelObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    typeObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    height (val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data () {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      tableHeight: 0,
    };
  },

  methods: {
    layoutFun(){
      this.$refs.carTable.doLayout();
    },
    handleSizeChange (val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange (val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset () {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
  },
};
</script>

<style></style>
