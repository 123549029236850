var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "overSpeed", staticClass: "over-speed" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.clickTab },
          model: {
            value: _vm.form.type,
            callback: function($$v) {
              _vm.$set(_vm.form, "type", $$v)
            },
            expression: "form.type"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "统计", name: "1" } },
            [
              _c(
                "div",
                { ref: "form", staticClass: "form-area" },
                [
                  _c(
                    "analysis-form",
                    {
                      attrs: {
                        mistakeList: _vm.mistakeList,
                        alarmTypeList: _vm.alarmTypeList,
                        showCar: false,
                        mistakeShow: true,
                        alarmTypeShow: true,
                        platformAlarmShow: true
                      },
                      on: { getFormVal: _vm.onSubmit }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.comArr.length === 0,
                            loading: _vm.downLoadStatus
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportData(1)
                            }
                          }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.state,
                        callback: function($$v) {
                          _vm.state = $$v
                        },
                        expression: "state"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 图表 ")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 报表 ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.state == 0
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "item item2",
                            staticStyle: { height: "380px" }
                          },
                          [
                            _c("charts", {
                              ref: "comCharts",
                              attrs: { option: _vm.item2Option, id: _vm.item2 }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _c("company-table", {
                    ref: "pagination1",
                    attrs: {
                      height: _vm.tableHeight,
                      total: _vm.total,
                      tableData: _vm.comArr
                    }
                  })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "车辆", name: "2" } },
            [
              _c(
                "div",
                { staticClass: "form-area" },
                [
                  _c(
                    "analysis-form",
                    {
                      attrs: {
                        mistakeList: _vm.mistakeList,
                        alarmTypeList: _vm.alarmTypeList,
                        mistakeShow: true,
                        platformAlarmShow: true,
                        alarmTypeShow: true
                      },
                      on: { getFormVal: _vm.onSubmit }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.carArr.length === 0,
                            loading: _vm.downLoadStatus
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportData(2)
                            }
                          }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("car-table", {
                ref: "pagination2",
                attrs: {
                  height: _vm.tableHeight,
                  total: _vm.total,
                  modelObj: _vm.modelObj,
                  typeObj: _vm.typeObj,
                  tableData: _vm.carArr
                },
                on: { sendInfo: _vm.getPageInfo }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "详情", name: "3" } },
            [_c("alarmDetail", { attrs: { alarmTypeValue: _vm.alarmType } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }