<template>
  <div class="multilevel">
    <el-table :data="tableData" :height="tableHeight" style="width: 100%">
      <el-table-column type="index" width="50" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="道路运输企业"
        :show-overflow-tooltip="true"
        :width="240 * $store.state.vehicle.screenCoefficient"
      ></el-table-column>
      <el-table-column label="20%以下">
        <el-table-column
          prop="lessTwentyFive"
          label="5分钟以下"
        ></el-table-column>
        <el-table-column
          prop="lessTwentyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="lessTwenty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="20%(含)-50%以下">
        <el-table-column
          prop="middleFiftyFive"
          label="5分钟以下"
        ></el-table-column>
        <el-table-column
          prop="middleFiftyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="middleFifty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="50%以上">
        <el-table-column
          prop="moreFiftyFive"
          label="5分钟以下"
        ></el-table-column>
        <el-table-column
          prop="moreFiftyTen"
          label="5(含)-10分钟"
          :width="100 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          prop="moreFifty"
          label="10(含)分钟以上"
          :width="120 * $store.state.vehicle.screenCoefficient"
        ></el-table-column>
      </el-table-column>
      <el-table-column prop="total" label="合计"></el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },
  watch: {
    height (val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data () {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      tableHeight: 0,
    };
  },

  methods: {
    handleSizeChange (val) {
      this.pageObj.pageSize = val;
    },
    handleCurrentChange (val) {
      this.pageObj.currentPage = val;
    },
    reset () {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
  },
  created () {
    this.tableHeight = this.height;
  },
};
</script>

<style></style>
